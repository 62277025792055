<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent
              title="Edition des templates de capacités d'une activité"
            />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row
                no-gutters
                justify="space-between"
                v-if="!loading && activity"
              >
                <div>
                  Edition des templates de capacités pour l'activité
                  {{ activity.digitalName }}
                </div>
              </v-row>
            </v-card-title>

            <!-- Treeview -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="12" align-self="center">
                  <TreeViewTemplateCapacityComponent
                    :treeNodeFactory="treeNodeFactory"
                    :tree="treemodel"
                    :editable="true"
                    :canRemoveNode="canRemoveElements"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { ActivityService } from "@/service/sfr/activity_service.js";
import { CapacitiesService } from "@/service/etablishment/capacities_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import TreeViewTemplateCapacityComponent from "@/components/ui/TreeViewCapacity/TreeViewTemplateCapacityComponent.vue";
import {
  TreeNodeFactory,
  TreeModelConverter,
} from "@/components/ui/TreeViewCapacity/TemplateTreeService.js";
import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddCapacities",
  components: {
    Workflow,
    TitleAndReturnComponent,
    TreeViewTemplateCapacityComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [ 
    WorkflowMixin, 
    RoleMixin, 
    TableViewEditWorkflowMixin, 
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      treeNodeFactory: TreeNodeFactory,
      treeModelConverter: TreeModelConverter,
      /**en cours de chargement. */
      loading: false,

      /**les service d'accès au APIs. */
      capacitiesService: null,
      activitiesService: null,
      establishmentsService: null,

      /** l'activité éditée */
      activity: null,

      /** L'arbre édité */
      treemodel: null,
      /** L'arbre initial */
      initialtreemodel: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        this.activity = await this.activitiesService.getActivityById(
          this.activityId
        );
        let capacitiesList =
          await this.capacitiesService.getCapacityTemplatesByActivity(
            this.activityId
          );
        let capacities = {
          capacities: capacitiesList,
        };
        this.treemodel =
          this.treeModelConverter.convertDtoToTreeModel(capacities);
        this.initialtreemodel = JSON.parse(JSON.stringify(this.treemodel));
      } catch (error) {
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        let dto = this.treeModelConverter.convertTreeModelToDto(
          this.treemodel,
          this.activityId
        );

        // on charge les capacités actuellement en base
        let existingCapacities =
          await this.capacitiesService.getCapacityTemplatesByActivity(
            this.activityId
          );

        // Création ou mise à jour des capacités du nouveau modèle
        for (let capa of dto) {
          if (capa.id) {
            await this.capacitiesService.update(capa);
          } else {
            await this.capacitiesService.create(capa);
          }
        }
        // suppression des capacités parentes qui n'existent plus dans le nouveau modèle
        for (let capacity of existingCapacities) {
          let alreadyExist = dto.find((capaDto) => capaDto.id === capacity.id);
          if (!alreadyExist) {
            await this.capacitiesService.delete(capacity.id);
          }
        }
        
        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    //TREE
    async getCapacityQuantitiesForNodeTree(node, mapByCapacity) {
      let quantitiesByEstablishments =
        await this.capacitiesService.getCapacityQuantities(node.payload.id);
      if (quantitiesByEstablishments && quantitiesByEstablishments.length > 0) {
        mapByCapacity.set(node, quantitiesByEstablishments);
      }
      if (node.children) {
        for (let subnode of node.children) {
          await this.getCapacityQuantitiesForNodeTree(subnode, mapByCapacity);
        }
      }
    },

    async canRemoveElements(node) {
      let mapQuantitiesByCapacity = new Map();
      await this.getCapacityQuantitiesForNodeTree(
        node,
        mapQuantitiesByCapacity
      );

      let canRemove = mapQuantitiesByCapacity.size === 0;
      if (!canRemove) {
        let errorMessage = "";
        let errorLightMessage = "";
        let mapEstablishments = new Map();

        errorLightMessage +=
          "Impossible de supprimer cette capacité. Des quantités y sont associées.";
        errorMessage +=
          "<b>Impossible de supprimer cette capacité. <br/>Des quantités sont associées à cette capacité et/ou à ses sous-capacités. </b><br/>";
        errorMessage += "Les établissements concernés sont : <br/>";

        for (let listOfEstablishmentQuantities of mapQuantitiesByCapacity.values()) {
          for (let establishmentQuantity of listOfEstablishmentQuantities) {
            if (!mapEstablishments.has(establishmentQuantity.establishmentId)) {
              let establishment = await this.establishmentsService.getById(
                establishmentQuantity.establishmentId
              );
              mapEstablishments.set(
                establishment.id,
                establishment.digitalName
              );
            }
          }            
        }

        for (let establishment of mapEstablishments.values()) {
          let establishmentMessage = establishment;
          errorMessage += establishmentMessage + "<br/>";
        }

        return {
          canOperate: false,
          error: errorMessage,
          errorLight: errorLightMessage,
        };
      }
      return { canOperate: true };
    },

    isDifferentTrees(tree1, tree2) {
      // si les arbres n'ont pas le même nombre d'enfants directs -> arbres différents
      if (
        tree1 &&
        tree2 &&
        tree1.children &&
        tree2.children &&
        tree1.children.length != tree2.children.length
      ) {
        return true;
      }
      // si les noms du noeud actifs sont différents  -> arbres différents
      if (tree1.name != tree2.name) {
        return true;
      }
      // pour tous les enfants de l'arbre 1
      for (let subnode1 of tree1.children) {
        let subnode2 = tree2.children.find((c) => c.id === subnode1.id);
        // si on ne trouve pas le node dans les enfants du second arbre -> arbres différents
        if (!subnode2) return true;
        // sinon appel récursif
        let different = this.isDifferentTrees(subnode1, subnode2);
        if (different) return true;
      }
      // pour tous les enfants de l'arbre 1
      for (let subnode2 of tree2.children) {
        let subnode1 = tree1.children.find((c) => c.id === subnode2.id);
        // si on ne trouve pas le node dans les enfants du premier arbre -> arbres différents
        if (!subnode1) return true;
      }

      return false;
    },
  },
  computed: {
    /**retourne true si entité est modifié */
    hasChanged() {
      let changed = false;
      if (this.initialtreemodel && this.treemodel) {
        changed = this.isDifferentTrees(this.treemodel, this.initialtreemodel);
      }
      return changed;
    },
  },
  mounted() {
    // initialisation des services vers l'api
    this.activitiesService = new ActivityService(this.$api);
    this.capacitiesService = new CapacitiesService(
      this.$api.getCapacitiesApi()
    );
    this.establishmentsService = new EstablishmentsService(this.$api);

    // initialisation des classes utilitaires de manipulation de l'arbre
    this.treeNodeFactory = new TreeNodeFactory();
    this.treeModelConverter = new TreeModelConverter(this.treeNodeFactory);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    if (this.$route.query) {
      if (this.$route.query.activity) {
        this.activityId = this.$route.query.activity;
      }
    }
    this.load();
  },
};
</script>

<style></style>
